import React, { useState, useEffect } from 'react';
import { object, string, number } from 'prop-types';
import { RichText } from 'prismic-reactjs';
import lozad from 'lozad';

import Modal from '@components/Modal';
import { dateToString } from '@helpers';

import style from './Item.module.scss';

const Item = ({ title, videourl, tag, date, index }) => {
  const [previewImage, setPreviewImage] = useState(null);
  const [modalIsOpen, setModalIsOpen] = useState();

  const handleOpenModal = () => setModalIsOpen(true);
  const handleCloseModal = () => setModalIsOpen(false);
  const url = videourl.url;
  const tagList = tag.split(/\s*,\s*/);

  useEffect(() => {
    if (!previewImage) {
      fetch(`https://noembed.com/embed?url=${url}`)
        .then((response) => response.json())
        .then((data) => {
          if (data.thumbnail_url) {
            const image = data.thumbnail_url;
            setPreviewImage(image);
          }
        });
    }
  }, [previewImage, modalIsOpen]);

  useEffect(() => {
    const observer = lozad();
    observer.observe();
  }, [previewImage]);

  return (
    <div className={style.item}>
      <div className={style.container}>
        <div className={style.preview} onClick={handleOpenModal}>
          {previewImage && index === 0 && (
            <img src={previewImage} loading="eager" alt="previewImage" />
          )}
          {index !== 0 && previewImage && (
            <img data-src={previewImage} className="lozad" alt="previewImage" />
          )}
        </div>
        <div className={style.title}>
          <RichText render={title.richText} />
        </div>
        <div className={style.wrapper}>
          <div className={style.tagList}>
            {tagList.map((tag, index) => {
              return (
                <div className={style.tag} key={`tag${index}${tag}`}>
                  {tag}
                </div>
              );
            })}
          </div>
          <div className={style.date}>{dateToString(date)}</div>
        </div>
      </div>
      {modalIsOpen && (
        <Modal
          open={modalIsOpen}
          closeModal={handleCloseModal}
          videoLink={url}
        />
      )}
    </div>
  );
};

Item.propTypes = {
  title: object,
  videourl: object,
  tag: string,
  date: string,
  index: number,
};

export default Item;
